<template>
  <v-app>
    <div id="exportContent">
      <div class="page">
        <div align="center">
          <table width="100%">
            <tr>
              <td width="100%">
                <div class="head text-center">
                  <h2>
                    ตัวอย่าง
                  </h2>
                  แบบคำขอเปลี่ยนตำแหน่ง/ย้ายข้าราชการครูและบุคลากรทางการศึกษา
                </div>
                <div class="head text-center">
                  และการย้ายข้าราชการพลเรือนสามัญ
                  ไปบรรจุและแต่งตั้งเป็นข้าราชการครู
                </div>
                <div class="head text-center">
                  และบุคลากรทางการศึกษา ตำแหน่งบุคลากรทางการศึกษาอื่นตามมาตรา 38
                  ค.(2)
                </div>
              </td>
              <td>
                <table>
                  <tr>
                    <td></td>
                    <td
                      class="td text-center text_j_1"
                      width="72px"
                      height="90px"
                    >
                   <v-img
                v-if="transference_personnel_38s.personnel_temporary_pic"
                contain
                height="90px"
                width="72px"
                :src="'https://hrvec.ovec.go.th/HRvecfiles/' + transference_personnel_38s.personnel_temporary_pic"
              ></v-img>
              <img
                v-else
                height="90px"
                width="72px"
                src="https://hrvec.ovec.go.th/HRvecfiles/blank-human-image.png"
              />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        <br />
        <table style="padding-top:5px" class="text_j" border="0" width="100%">
          <tr>
            <td width="50%"></td>
            <td width="15%">
              <div class="regular16">เขียนที่</div>
            </td>
            <td width="35%">
              <div class="regular16 td_line">
                {{ transference_personnel_38s.agency }}
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <div class="regular16">วันที่ยื่นคำร้อง</div>
            </td>
            <td>
              <div class="regular16 td_line">
                {{
                  transference_personnel_38s.tp_date_time
                    | moment("add", "543 year")
                    | moment("D MMMM YYYY")
                }}
              </div>
            </td>
          </tr>
        </table>

        <br />

        <table border="0" width="100%">
          <tr>
            <td width="100%">
              <div class="regular16">
                เรื่อง ขอเปลี่ยนตำแหน่ง/ย้าย
                ไปแต่งตั้งให้ดำรงตำแหน่งบุคลากรทางการศึกษาอื่นตามมาตรา 38 ค.(2)
              </div>
            </td>
          </tr>
        </table>

        <table border="0" class="text_j" width="100%">
          <tr>
            <td width="100%">
              <div class="regular16">
                เรียน เลขาธิการคณะกรรมการการอาชีวศึกษา
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%"></td>
            <td width="15%">ด้วยข้าพเจ้า</td>
            <td width="45%">
              <div class="regular16 td_line" align="center">
                {{
                  transference_personnel_38s.title_s +
                    transference_personnel_38s.frist_name +
                    " " +
                    transference_personnel_38s.last_name
                }}
              </div>
            </td>
            <td width="10%">
              <div class="regular16" align="center">อายุ</div>
            </td>
            <td>
              <div class="regular16 td_line" align="center">
                {{ transference_personnel_38s.tp_ageUser }}
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" border="0" width="100%" align="center">
          <tr>
            <td width="10%">
              <div class="regular16">เกิดวันที่</div>
            </td>
            <td width="5%">
              <div class="regular16 td_line" align="center">
                {{ transference_personnel_38s.brith_day || "-" }}
              </div>
            </td>
            <td width="5%">
              <div class="regular16 text-center">เดือน</div>
            </td>
            <td width="10%">
              <div class="regular16 td_line" align="center">
                {{
                  transference_personnel_38s.brith_month
                    | moment("add", "")
                    | moment("MMMM")
                }}
              </div>
            </td>
            <td width="10%">
              <div class="regular16 text-center">พ.ศ.</div>
            </td>
            <td width="5%">
              <div class="regular16 td_line" align="center">
                {{ transference_personnel_38s.brith_year || "-" }}
              </div>
            </td>
            <td width="25%">
              <div class="regular16" align="center">เลขประจำตัวประชาชน</div>
            </td>
            <td width="30%">
              <div class="regular16 td_line" align="center">
                {{ transference_personnel_38s.tp_id_card }}
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" border="0" width="100%" align="center">
          <tr>
            <td width="20%">
              <div class="regular16">ปัจจุบันเป็นข้าราชการ/</div>
            </td>
            <td width="40%">
              <div class="regular16 td_line text-center" align="left">
                <span v-if="transference_personnel_38s.tp_type_move === '38'"
                  >บุคลากรทางการศึกษาอื่นตามมาตรา 38 ค.(2)</span
                >
                <span
                  v-else-if="
                    transference_personnel_38s.tp_type_move === 'civil'
                  "
                  >ข้าราชการพลเรือนสามัญ</span
                >
                <span
                  v-else-if="
                    transference_personnel_38s.tp_type_move === 'teacher'
                  "
                  >ข้าราชการครูและบุคลากรทางการศึกษา</span
                >
              </div>
            </td>

            <td width="10%">
              <div class="regular16 text-center">ตำแหน่ง</div>
            </td>
            <td width="30%">
              <div class="regular16 td_line text-center" align="left">
                {{ transference_personnel_38s.position_name }}
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" border="0" width="100%" align="center">
          <tr>
            <td>
              <div class="regular16">ระดับ/วิทยฐานะ</div>
            </td>
            <td>
              <div class="regular16 td_line text-center" align="left">
                {{ transference_personnel_38s.rang_name }}
              </div>
            </td>

            <td>
              <div class="regular16 text-center">รับเงินเดือน ระดับ/ชั้น</div>
            </td>
            <td>
              <div class="regular16 td_line text-center" align="left">
                {{ transference_personnel_38s.rang_level }}
              </div>
            </td>

            <td>
              <div class="regular16 text-center">อัตรา/ขั้น</div>
            </td>
            <td>
              <div class="regular16 td_line text-center" align="left">
                {{ transference_personnel_38s.salary_s }}
              </div>
            </td>

            <td>
              <div class="regular16 text-center">บาท</div>
            </td>
          </tr>
        </table>

        <table class="text_j" border="0" width="100%" align="center">
          <tr>
            <td width="30%">
              <div class="regular16">สังกัด/หน่วยงานการศึกษา/สำนัก</div>
            </td>
            <td>
              <div class="regular16 td_line text-center">
                {{ transference_personnel_38s.user_firstname }}
                สังกัดสำนักงานคณะกรรมการการอาชีวศึกษา
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" border="0" width="100%" align="center">
          <tr>
            <td width="">
              <div class="regular16 td_line text-center">กระทรวงศึกษาธิการ</div>
            </td>

            <td width="">
              <div class="regular16">ตั้งอยู่ที่</div>
            </td>

            <td>
              <div class="regular16 td_line text-center">
                {{ transference_personnel_38s.user_address }}
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" border="0" width="100%" align="center">
          <tr>
            <td width="">
              <div class="regular16">ตำบล/แขวง</div>
            </td>
            <td>
              <div class="regular16 td_line text-center">
                {{ transference_personnel_38s.district_name }}
              </div>
            </td>
            <td width="">
              <div class="regular16 text-center">อำเภอ/เขต</div>
            </td>
            <td>
              <div class="regular16 td_line text-center">
                {{ transference_personnel_38s.prefecture_name }}
              </div>
            </td>
            <td width="">
              <div class="regular16 text-center">จังหวัด</div>
            </td>
            <td>
              <div class="regular16 td_line text-center">
                {{ transference_personnel_38s.province_name }}
              </div>
            </td>
            <td width="">
              <div class="regular16 text-center">รหัสไปรษณีย์</div>
            </td>
            <td>
              <div class="regular16 td_line text-center">
                {{ transference_personnel_38s.post }}
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" border="0" width="100%" align="center">
          <tr>
            <td width="">
              <div class="regular16">โทรศัพท์</div>
            </td>
            <td>
              <div class="regular16 td_line text-center">
                {{ transference_personnel_38s.user_tel_p || "-" }}
              </div>
            </td>
            <td width="">
              <div class="regular16 text-center">โทรสาร</div>
            </td>
            <td>
              <div class="regular16 td_line text-center">
                {{ transference_personnel_38s.user_fax }}
              </div>
            </td>
            <td width="">
              <div class="regular16 text-center">E-mail</div>
            </td>
            <td>
              <div class="regular16 td_line text-center">
                {{ transference_personnel_38s.user_email }}
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%"></td>
            <td>ปัจจุบันอยู่บ้านเลขที่</td>
            <td>
              <div class="regular16 td_line text-center" align="center">
                {{ transference_personnel_38s.pt_homeid }}
              </div>
            </td>

            <td class="text-center">หมู่ที่</td>
            <td>
              <div class="regular16 td_line text-center" align="center">
                {{ transference_personnel_38s.pt_moo }}
              </div>
            </td>

            <td class="text-center">ถนน</td>
            <td>
              <div class="regular16 td_line" align="center">
                {{ transference_personnel_38s.pt_road }}
              </div>
            </td>

            <td class="text-center">ตำบล/แขวง</td>
            <td>
              <div class="regular16 td_line" align="center">
                {{ transference_personnel_38s.Userdistrict }}
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td>อำเภอ/เขต</td>
            <td>
              <div class="regular16 td_line text-center" align="center">
                {{ transference_personnel_38s.Userprefecture }}
              </div>
            </td>

            <td class="text-center">จังหวัด</td>
            <td>
              <div class="regular16 td_line text-center" align="center">
                {{ transference_personnel_38s.Userprovince }}
              </div>
            </td>

            <td class="text-center">รหัสไปรษณีย์</td>
            <td>
              <div class="regular16 td_line" align="center">
                {{ transference_personnel_38s.Userpost }}
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td>โทรศัพท์เคลื่อนที่</td>
            <td>
              <div class="regular16 td_line text-center" align="center">
                {{ transference_personnel_38s.tel_p }}
              </div>
            </td>

            <td class="text-center">E-mail</td>
            <td>
              <div class="regular16 td_line text-center" align="center">
                {{ transference_personnel_38s.e_mail }}
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td>
              มีความสมัครใจขอเปลี่ยนตำแหน่ง/ย้าย/โอน
              ไปแต่งตั้งให้ดำรงตำแหน่งบุคลากรทางการศึกษาอื่นตามมาตรา 38 ค.(2)
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td>ตำแหน่ง</td>
            <td>
              <div class="regular16 td_line text-center" align="center">
                {{ transference_personnel_38s.tp_positon_select }}
              </div>
            </td>

            <td class="text-center">ตำแหน่งเลขที่</td>
            <td>
              <div class="regular16 td_line text-center" align="center">
                {{ transference_personnel_38s.tp_positonid_select }}
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <!--  <td>ระดับตำแหน่ง</td>
            <td>
              <div class="regular16 td_line text-center" align="center">{{ transference_personnel_38s.tp_positonlevel_select }}</div>
            </td> -->

            <td class="">สังกัด</td>
            <td>
              <div class="regular16 td_line text-center" align="center">
                {{ transference_personnel_38s.userUnder }}
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%"></td>
            <td>
              <div class="regular16">
                <span class="bold16">ข้าพเจ้าขอเรียนว่า</span>
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td>
              <div class="regular16 div-indent">
                1. ข้าพเจ้ามีคุณสมบัติทั่วไปตามมาตรา 30
                แห่งพระราชบัญญัติระเบียบข้าราชการครูและบุคลากร ทางการศึกษา พ.ศ.
                2547
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%"></td>
            <td width="35%">2. เริ่มเข้ารับราชการ เป็นข้าราชการ</td>
            <td class="text-center">
              <div class="regular16 td_line text-center" align="center">
                {{ transference_personnel_38s.tp_govbegin }}
              </div>
            </td>

            <td class="text-center">เมื่อวันที่</td>
            <td>
              <div class="regular16 td_line text-center" align="center">
                {{ transference_personnel_38s.tp_govbegindate }}
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" border="0" width="100%" align="center">
          <tr>
            <td width="10%">
              <div class="regular16">ตำแหน่ง</div>
            </td>
            <td width="30%">
              <div class="regular16 td_line text-center" align="left">
                {{ transference_personnel_38s.tp_govbeginposition }}
              </div>
            </td>

            <td>
              <div class="regular16">ระดับ</div>
            </td>
            <td>
              <div class="regular16 td_line text-center" align="left">
                {{ transference_personnel_38s.tp_govbeginlevel }}
              </div>
            </td>

            <td>
              <div class="regular16">สังกัด</div>
            </td>
            <td>
              <div class="regular16 td_line text-center" align="left">
                {{ transference_personnel_38s.tp_govbeginunder }}
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" border="0" width="100%" align="center">
          <tr>
            <td width="10%">
              <div class="regular16">ส่วนราชการ</div>
            </td>
            <td width="30%">
              <div class="regular16 td_line text-center" align="left">
                {{ transference_personnel_38s.tp_govbeginagency }}
              </div>
            </td>

            <td>
              <div class="regular16">กระทรวง</div>
            </td>
            <td>
              <div class="regular16 td_line text-center" align="left">
                {{ transference_personnel_38s.tp_govbeginministry }}
              </div>
            </td>
          </tr>
        </table>

        <div class="text-right mt-5">
          3.วินัย/คดีความ...
        </div>
        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 2 -->

        <div class="text-center mt-10">
          -2-
        </div>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%"></td>
            <td>3. วินัย/คดีความ</td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="15%"></td>
            <td width="35%">
              (
              <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_discipline === '1'"
                >mdi-check-bold</v-icon
              >
              ) เคยถูกลงโทษทางวินัย
            </td>
            <td width="5%"></td>
            <td width="35%">
              (
              <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_discipline === '0'"
                >mdi-check-bold</v-icon
              >
              ) ไม่เคยถูกลงโทษทางวินัย
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="15%"></td>
            <td width="35%">
              (
              <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_pro_discipline === '1'"
                >mdi-check-bold</v-icon
              >
              ) อยู่ในระหว่างถูกดำเนินการทางวินัย
            </td>
            <td width="5%"></td>
            <td width="35%">
              (
              <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_pro_discipline === '0'"
                >mdi-check-bold</v-icon
              >
              ) ไม่อยู่ในระหว่างถูกดำเนินการทางวินัย
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="15%"></td>
            <td width="35%">
              (
              <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_criminal === '1'"
                >mdi-check-bold</v-icon
              >
              ) อยู่ในระหว่างถูกดำเนินคดีอาญา
            </td>
            <td width="5%"></td>
            <td width="35%">
              (
              <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_criminal === '0'"
                >mdi-check-bold</v-icon
              >
              ) ไม่อยู่ในระหว่างถูกดำเนินคดีอาญา
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="15%"></td>
            <td width="35%">
              (
              <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_bankrupt === '1'"
                >mdi-check-bold</v-icon
              >
              ) อยู่ในระหว่างถูกดำเนินคดีล้มละลาย
            </td>
            <td width="5%"></td>
            <td width="35%">
              (
              <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_bankrupt === '0'"
                >mdi-check-bold</v-icon
              >
              ) ไม่อยู่ในระหว่างถูกดำเนินคดีล้มละลาย
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%"></td>
            <td>4. เหตุผลการขอเปลี่ยนตำแหน่ง/ย้าย</td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%"></td>
            <td>
              5. ประวัติการศึกษา (ให้ระบุคุณวุฒิทุกระดับ
              ตั้งแต่ระดับประกาศนียบัตรวิชาชีพ (ปวช.)หรือเทียบเท่าขึ้นไป)
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" border="1" align="center">
          <tr>
            <td width="5%" class="text-center">ที่</td>
            <td class="text-center">คุณวุฒิ</td>
            <td class="text-center">สาขา/วิชาเอก</td>
            <td class="text-center">สถาบันการศึกษา</td>
          </tr>

          <tr v-for="(item, index) in personnel_educations" :key="item.id">
            <td width="5%" class="text-center">{{ (index += 1) }}</td>
            <td class="text-center">{{ item.faculty_name }}</td>
            <td class="text-center">{{ item.branch_name }}</td>
            <td class="text-center">{{ item.academy_name }}</td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%"></td>
            <td>
              6. ประวัติการรับราชการ (ระบุเฉพาะที่มีการเปลี่ยนตำแหน่ง/ระดับ
              หรือเปลี่ยนหน่วยงาน)
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" border="1" align="center">
          <tr>
            <td width="5%" class="text-center">ที่</td>
            <td class="text-center">วัน เดือน ปี</td>
            <td class="text-center">ตำแหน่ง/ระดับ</td>
            <td class="text-center">สังกัด</td>
            <td class="text-center">หมายเหตุ</td>
          </tr>

          <tr v-for="(item, index) in personnel_work_historys" :key="item.id">
            <td width="5%" class="text-center">{{ index + 1 }}</td>
            <td class="text-center">
              {{
                item.date_begin
                  | moment("add", "543 year")
                  | moment("D MMMM YYYY")
              }}
            </td>
            <td class="text-center">{{ item.position_s }}</td>
            <td class="text-center">
              <span v-if="item.pwh_type === '0'">{{
                item.college_name_work
              }}</span>
              <span v-else-if="item.pwh_type === '1'">{{
                item.pwh_affiliation
              }}</span>
            </td>
            <td class="text-center"></td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%"></td>
            <td>
              สำหรับตำแหน่งประเภทวิชาการให้ระบุว่าบรรจุและแต่งตั้งโดยวิธีใด
              พร้อมเอกสารหลักฐาน
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td>
              <div class="div-indent">
                7. ผลงานหรือผลการปฏิบัติงานที่ผ่านมา
                และผลงานอื่นที่แสดงให้เห็นถึงความรู้ความสามารถพิเศษ
                ที่เป็นประโยชน์ต่อการปฏิบัติงานในตำแหน่งบุคลากรทางการศึกษาอื่นตามมาตรา
                38 ค.(2) ที่ประสงค์จะขอเปลี่ยนตำแหน่ง/ย้าย
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%"></td>
            <td>7.1 ผลงานหรือผลการปฏิบัติงานที่ผ่านมา (ย้อนหลัง 5 ปี)</td>
          </tr>
        </table>

        <div class="text-right">
          <table class="text_j" width="100%" border="1" align="center">
            <tr>
              <td width="5%" class="text-center">ที่</td>
              <td class="text-center">วัน เดือน ปี</td>
              <td class="text-center">ผลงาน</td>
              <td class="text-center">ระดับ</td>
              <td class="text-center">ประเภท</td>
            </tr>

            <tr v-for="(item, index) in personnel_awards" :key="item.id">
              <td width="5%" class="text-center">{{ index + 1 }}</td>
              <td class="text-center">
                {{
                  item.performance_time
                    | moment("add", "543 year")
                    | moment("D MMMM YYYY")
                }}
              </td>
              <td class="text-center">{{ item.performance_name }}</td>
              <td class="text-center">
                <span v-if="item.award_level == 'college'">ระดับวิทยาลัย</span>
                <span v-if="item.award_level == 'provincial'"
                  >ระดับสำนักงานอาชีวศึกษาจังหวัด</span
                >
                <span v-if="item.award_level == 'sector'">ระดับภาค</span>
                <span v-if="item.award_level == 'vocational'"
                  >ระดับอาชีวศึกษา</span
                >
                <span v-if="item.award_level == 'nation'">ระดับชาติ</span>
                <span v-if="item.award_level == 'word'">ระดับโลก</span>
              </td>
              <td class="text-center">
                <span v-if="item.type_performance == 'skill'"
                  >การแข่งขันทักษะ</span
                >
                <span v-if="item.type_performance == 'innovation'"
                  >การประกวดสิ่งประดิษฐ์</span
                >
                <span v-if="item.type_performance == 'award'"
                  >รางวัลเชิดชูเกียรติ</span
                >
                <span v-if="item.type_performance == 'etc'"
                  >อื่นๆ ( {{ item.personnel_awardEtc }} )
                </span>
              </td>
            </tr>
          </table>
        </div>

        <div class="text-right mt-5">
          7.2 ผลงาน...
        </div>

        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 3 -->

        <div class="text-center mt-10">
          -3-
        </div>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%"></td>
            <td>7.2 ผลงานที่เคยเสนอเพื่อเลื่อนตำแหน่ง/วิทฐานะ (ถ้ามี)</td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td>
              <div class="regular16 div-indent td_line">
               {{ transference_personnel_38s.tp_performance_ever }}
              </div>
            </td>
          </tr>
        </table>


        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="10%"></td>
            <td>
              7.3
              ผลงานอื่นที่แสดงให้เห็นถึงความรู้ความสามารถพิเศษที่เป็นประโยชน์ต่อการปฏิบัติงาน
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td>
              <div class="regular16 div-indent td_line">
               {{ transference_personnel_38s.tp_performance_special }}
              </div>
            </td>
          </tr>
        </table>


        <table class="text_j" width="100%" align="center">
          <tr>
            <td>
              <div class="div-indent">
                8. ปัจจุบันข้าพเจ้าได้รับเงินประจำตำแหน่ง/ค่าตอบแทน/วิทยฐานะ
                เดือนละ.... {{ numberWithCommas(transference_personnel_38s.salary_s) }} ....บาท
                เมื่อได้เปลี่ยนตำแหน่ง/ย้าย/โอนมาดำรงตำแหน่งใด
                ข้าพเจ้ายินยอมที่จะรับเงินประจำตำแหน่ง/ค่าตอบแทน
                ตามที่กำหนดสำหรับตำแหน่งนั้น
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td>
              <div class="div-indent">
                9. เอกสารหลักฐานต่าง ๆ
                ที่ส่งเพื่อประกอบการพิจารณาโดยมีผู้รับรองสำเนาตามระเบียบสำนักนายกรัฐมนตรี
                ว่าด้วยงานสารบรรณ ดังนี้
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" width="100%" align="center">
          <tr>
            <td width="15%"></td>
            <td>( <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_evidence_info_1 === '1'"
                >mdi-check-bold</v-icon
              > ) 9.1</td>
            <td>
              สำเนาปริญญาบัตร หรือเอกสารหลักฐานอื่นที่แสดงการสำเร็จการศึกษา
            </td>
          </tr>

          <tr>
            <td></td>
            <td>( <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_evidence_info_2 === '1'"
                >mdi-check-bold</v-icon
              > ) 9.2</td>
            <td>สำเนาระเบียนแสดงผลการศึกษา (Transcript)</td>
          </tr>

          <tr>
            <td></td>
            <td>( <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_evidence_info_3 === '1'"
                >mdi-check-bold</v-icon
              > ) 9.3</td>
            <td>
              สำเนาทะเบียนประวัติข้าราชการ (ก.พ.7) ที่มีรายละเอียดครบถ้วนสมบูรณ์
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>ตั้งแต่วันที่เริ่มรับราชการจนถึงปัจจุบัน</td>
          </tr>

          <tr>
            <td></td>
            <td>( <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_evidence_info_4 === '1'"
                >mdi-check-bold</v-icon
              > ) 9.4</td>
            <td>หนังสือยินยอมการรับเงินเดือนที่ต่ำกว่าเดิม (ถ้ามี)</td>
          </tr>

          <tr>
            <td></td>
            <td>( <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_evidence_info_5 === '1'"
                >mdi-check-bold</v-icon
              > ) 9.5</td>
            <td>สำเนาเอกสารหลักฐานที่แสดงผลงานหรือผลการปฏิบัติงาน</td>
          </tr>

          <tr>
            <td></td>
            <td>( <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_evidence_info_6 === '1'"
                >mdi-check-bold</v-icon
              > ) 9.6</td>
            <td>
              สำเนาเอกสารหลักฐานที่แสดงถึงความรู้ความสามารถพิเศษที่เป็นประโยชน์
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>ต่อการปฏิบัติงานในตำแหน่งที่จะขอเปลี่ยนตำแหน่ง/ย้าย</td>
          </tr>

          <tr>
            <td></td>
            <td>( <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_evidence_info_7 === '1'"
                >mdi-check-bold</v-icon
              > ) 9.7</td>
            <td>
              สำเนาเอกสารหลักฐานอื่นที่เกี่ยวข้อง เช่น
              หนังสือสำคัญการเปลี่ยนชื่อตัว ชื่อสกุล
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>ใบสำคัญการสมรส เป็นต้น (ถ้ามี)</td>
          </tr>

          <tr>
            <td></td>
            <td>( <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_evidence_info_8 === '1'"
                >mdi-check-bold</v-icon
              > ) 9.8</td>
            <td>
              เอกสารหลักฐานอื่นที่ผู้ขอเปลี่ยนตำแหน่ง/ย้าย
              เห็นว่าเป็นประโยชน์ในการพิจารณา
            </td>
          </tr>

          <tr>
            <td></td>
            <td>( <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_evidence_info_9 === '1'"
                >mdi-check-bold</v-icon
              > ) 9.9</td>
            <td>
              กรณีบรรจุเป็นข้าราชการในตำแหน่งประเภททั่วไป/ในสายงานที่เริ่มต้นจากระดับ
              1
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>
              และระดับ 2 ต่อมาได้เปลี่ยนประเภทตำแหน่งเป็นประเภทวิชาการ
              ให้ส่งเอกสารหลักฐาน
            </td>
          </tr>

          <tr>
            <td></td>
            <td></td>
            <td>การเปลี่ยนประเภทตำแหน่ง</td>
          </tr>

          <tr>
            <td></td>
            <td>( <v-icon
                color="black"
                v-if="transference_personnel_38s.tp_evidence_info_10 === '1'"
                >mdi-check-bold</v-icon
              > ) 9.10</td>
            <td>
              อื่น ๆ {{ transference_personnel_38s.tp_evidence_info_10_detail }}
            </td>
          </tr>
        </table>

        <div class="text-right mt-5">
          ข้าพเจ้าขอรับรอง...
        </div>

        <!-- New Page -->
        <p style="page-break-before: always;">&nbsp;</p>
        <!-- 4 -->

        <div class="text-center mt-10">
          -4-
        </div>

        <table class="text_j" border="0" width="100%" align="center">
          <tr>
            <td>
              <div class="div-indent ">
                ข้าพเจ้าขอรับรองว่าข้อความและเอกสารหลักฐานตามที่ระบุและยื่นขอเปลี่ยนตำแหน่ง/ย้าย/โอนไว้นี้ถูกต้องตรงตามความเป็นจริงทุกประการ
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" border="0" width="100%" align="center">
          <tr>
            <td>
              <div class="div-indent ">
                จึงเรียนมาเพื่อโปรดพิจารณา
              </div>
            </td>
          </tr>
        </table>

        <table class="text_j" border="0" width="100%" align="center">
          <tr>
            <td width="20%"></td>
            <td width="80%">
              <div class="text-center">
                ขอแสดงความนับถือ
              </div>
            </td>
          </tr>
          <tr>
            <td></td>
            <td class="text-center">
              <br />
              (ลงชื่อ)....................................................................
            </td>
          </tr>

          <tr>
            <td></td>
            <td class="text-center">
              (
              {{
                transference_personnel_38s.title_s +
                  transference_personnel_38s.frist_name +
                  " " +
                  transference_personnel_38s.last_name
              }}
              )
            </td>
          </tr>

          <tr>
            <td></td>
            <td class="text-center">
              ตำแหน่ง {{ transference_personnel_38s.position_name }}
            </td>
          </tr>
        </table>      
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    ApiKey: "HRvec2021",
    transference_personnel_38s: [],
    transference_personnel_38s: [],
    personnel_educations: [],
    transference_locations: [],
    transference_personnel_38s_switch: [],
    province_sh_s: [],
    province_sh_fm: [],
    province_sh_m: [],
    valid: true,
    personnel_award_id: [],
    periods: [],
    personnel_work_historys: [],
    personnel_awards: []
  }),

  async mounted() {
    await this.sweetAlertLoading();
    await this.periodQueryAll();
    await this.transference_personnel_38sQueryAll();
    await this.personnel_educationsQueryAll();
    await this.transference_locationsQueryAll();
    await this.transference_personnel_38s_switchQueryAll();
    await this.updatetransference_personnel_38s_award();
    await this.personnel_work_historysQueryAll();
    await this.personnel_awardsQueryAll();
    Swal.close();
  },

  methods: {
    numberWithCommas(x) {
        x = x.toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
        return x;
      },
    async personnel_awardsQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("personnel_award.php", {
          ApiKey: this.ApiKey,
          personnel_awardid_card: this.transference_personnel_38s.id_card
        })
        .finally(() => (this.loading = false));
      this.personnel_awards = result.data;
    },

    async personnel_work_historysQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("personnel_work_history.php", {
          ApiKey: this.ApiKey,
          id_card: this.transference_personnel_38s.id_card
        })
        .finally(() => (this.loading = false));
      this.personnel_work_historys = result.data;
    },

    async periodQueryAll() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable_process: "1",
        period_id: "3801"
      });
      this.periods = result_period.data;
    },

    async updatetransference_personnel_38s_award() {
      let result = await this.$http.post("personnel_award.php", {
        ApiKey: this.ApiKey,
        id_pa: this.transference_personnel_38s.tp_award
      });
      this.personnel_award_id = result.data;
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async transference_personnel_38sQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("transference_personnel_38.php", {
          ApiKey: this.ApiKey,
          tp_id_ref: this.tp_id_ref
        })
        .finally(() => (this.loading = false));
      this.transference_personnel_38s = result.data;
    },

    async transference_personnel_38s_switchQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("personnel_temporary.php", {
          ApiKey: this.ApiKey,
          id_card: this.transference_personnel_38s.switch_position
        })
        .finally(() => (this.loading = false));
      this.transference_personnel_38s_switch = result.data;
    },

    async personnel_educationsQueryAll() {
      let result = await this.$http.post("personnel_education.php", {
        ApiKey: this.ApiKey,
        id_card: this.transference_personnel_38s.id_card
      });
      this.personnel_educations = result.data;
    },

    async transference_locationsQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("transference_location.php", {
          ApiKey: this.ApiKey,
          id_ref: this.tp_id_ref
        })
        .finally(() => (this.loading = false));
      this.transference_locations = result.data;
    },

    Export2Doc(element, filename) {
      var preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
      var postHtml = "</body></html>";
      var html =
        preHtml + document.getElementById(element).innerHTML + postHtml;

      var blob = new Blob(["\ufeff", html], {
        type: "application/msword"
      });

      // Specify link url
      var url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(html);

      // Specify file name
      filename = filename ? filename + ".doc" : "document.doc";

      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        // Create a link to the file
        downloadLink.href = url;

        // Setting the file name
        downloadLink.download = filename;

        //triggering the function
        downloadLink.click();
      }

      document.body.removeChild(downloadLink);
    }
  },
  computed: {
    dateToday() {
      return new Date();
    },
    tp_id_ref() {
      return this.$route.query.tp_id_ref;
    },
    id_card_s() {
      let result = this.tp_id_ref.slice(5);
      return result;
    },
    day_tims() {
      let period_start = String(this.periods.period_start);
      let split_start = period_start.split("-");
      let result = split_start[2];
      return result;
    },
    marital_status() {
      let marital_status = this.transference_personnel_38s.marital_status;
      let marital_result;
      if (marital_status == "single") {
        marital_result = "โสด";
      } else if (marital_status == "married") {
        marital_result = "สมรส";
      } else if (marital_status == "widow") {
        marital_result = "หม้าย";
      } else if (marital_status == "divorce") {
        marital_result = "หย่า";
      } else if (marital_status == "separate") {
        marital_result = "แยกกันอยู่";
      }
      return marital_result;
    },

    date_today_cal() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },

    get_gov_Age() {
      let today = new Date(this.date_today_cal);
      let DOB = new Date(this.transference_personnel_38s.ser_date);
      let totalMonths =
        (today.getFullYear() - DOB.getFullYear()) * 12 +
        today.getMonth() -
        DOB.getMonth();
      totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
      let years = today.getFullYear() - DOB.getFullYear();
      if (DOB.getMonth() > today.getMonth()) years = years - 1;
      else if (DOB.getMonth() === today.getMonth())
        if (DOB.getDate() > today.getDate()) years = years - 1;

      let days;
      let months;

      if (DOB.getDate() > today.getDate()) {
        months = totalMonths % 12;
        if (months == 0) months = 11;
        let x = today.getMonth();
        switch (x) {
          case 1:
          case 3:
          case 5:
          case 7:
          case 8:
          case 10:
          case 12: {
            let a = DOB.getDate() - today.getDate();
            days = 31 - a;
            break;
          }
          default: {
            let a = DOB.getDate() - today.getDate();
            days = 30 - a;
            break;
          }
        }
      } else {
        days = today.getDate() - DOB.getDate();
        if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
        else months = totalMonths % 12;
      }
      let age = years + " ปี " + months + " เดือน " + days + " วัน";
      return age;
    }
  },
  props: {
    source: String
  }
};
</script>
<style>
@import url(
  https://fonts.googleapis.com/css?family=Prompt:400,
  300|Roboto:400,
  300&subset=latin,
  thai
);

body {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16px;
  font-weight: 250;
}

u {
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

* {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.table,
.th,
.td {
  border-bottom: 0.5px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-collapse: collapse;
}

.text_j {
  border-collapse: collapse;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1.5;
}

tfoot tr:hover {
  background-color: initial;
}

tbody tr:hover {
  background-color: inherit;
}

td,
th {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
}

tfoot td {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-weight: bold;
}

#verticaltext {
  writing-mode: tb-rl;
  filter: flipv fliph;
  -webkit-transform: rotate(-90deg);
  white-space: nowrap;
  display: block;
  overflow: hidden;
  padding: 0;
}

.page {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  width: 21cm;
  min-height: 29.7cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-top: 0cm;
  margin-right: auto;
  margin-bottom: 0cm;
  margin-left: auto;
  padding-top: 1.5cm;
  padding-right: 1.5cm;
  padding-bottom: 1.75cm;
  padding-left: 1.5cm;
}

.div-indent {
  text-indent: 3em;
}
.div-indent-sec {
  text-indent: 6.5em;
}

.head {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
  line-height: 1;
}

.regular12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
}

.regular16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
}

.bold16 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 16pt;
  font-weight: bold;
}

.blod12 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 14pt;
  font-weight: bold;
}

.blackRegula10 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 12pt;
}

.blackBold18 {
  font-family: "TH SarabunIT๙", "TH SarabunPSK";
  font-size: 18pt;
  font-weight: bold;
}

.largerCheckbox {
  width: 30px;
  height: 30px;
  color: blue;
}

.td_line {
  border-bottom: 1pt dotted black;
}

/*.subpage {
    height: 247mm;
  }*/

.BlackBold10 {
  font-weight: bold;
}

#content {
  display: table;
}

#pageFooter {
  display: table-footer-group;
}

#pageFooter:after {
  counter-increment: page;
  content: counter(page);
}

@media print {
  .page {
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    padding-top: 1.5cm;
    padding-right: 1.5cm;
    padding-bottom: 1.5cm;
    padding-left: 1.5cm;
  }

  .head {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  .regular12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .regular16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
  }

  .bold16 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blod12 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 16pt;
    font-weight: bold;
  }

  .blackRegula10 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 14pt;
  }

  .blackBold18 {
    font-family: "TH SarabunIT๙", "TH SarabunPSK";
    font-size: 18pt;
    font-weight: bold;
  }

  /* .head {
      font-size: 18px;
      font-weight: bold;
    }
    .regular12 {
      font-size: 12px;
    }
    .blod12 {
      font-size: 12px;
      font-weight: bold;
    }
    .blackRegula8 {
      font-size: 8px;
    } */
  .noprint {
    display: none;
  }
}
</style>
